import TipoFiltroEnum from '@bubotech/sumora-react-components/lib/cardfilters/enumerations/tipo-filtro-enum';
import { ButtonGrid, DataTable, GroupButtonGrid } from '@bubotech/sumora-react-components/lib';
import { useComponentDidMount } from '@bubotech/sumora-react-components/lib/utils/hooks';
import ScreensKeysEnum from 'root-components/cardfilters/screens-keys';
import AppLayoutActions from 'root-states/actions/app-layout-actions';
import CardFilters from 'root-components/cardfilters/card-filters';
import LoadingSwal from 'root-components/loadingswal/loading-swal';
import { DispatchAction } from 'root-states/root-dispatcher';
import ColaboradorAPI from 'root-resources/api/colaborador';
import { useDispatch, useSelector } from 'react-redux';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import EntregaAPI from 'root-resources/api/entrega';
import EntregaReportApi from 'root-resources/api/entrega-report';
import ClienteAPI from 'root-resources/api/cliente';
import UsuarioAPI from 'root-resources/api/usuario';
import FilialAPI from 'root-resources/api/filial';
import Colaborador from 'root-models/colaborador';
import { useStyles } from './entregas.styles';
import Swal from 'root-components/swal/swal';
import { MainStateType } from 'root-states';
import { useEffect, useState } from 'react';
import Cliente from 'root-models/cliente';
import Entrega from 'root-models/entrega';
import Usuario from 'root-models/usuario';
import Filial from 'root-models/filial';
import { Dispatch } from 'redux';
import moment from 'moment';

export type ListaEntregaPropType = {};
interface Params {
  dtInicio?:string | null, dtFim?:string | null, params?: string | null
}

/**
 * View de vizualização da lista de entregas
 *
 * @author Matheus ROdrigues <matheus.rodrigues@kepha.com.br>
 * @param {ListaEntregaPropType} props
 */

function ListaEntrega(props: ListaEntregaPropType): JSX.Element {
  const filtros = useSelector<MainStateType, any>((state) => state.cardFiltersReducer.data);
  const appLayoutActions = new AppLayoutActions(useDispatch<Dispatch<DispatchAction>>());
  const [colaboradoresOptions, setColaboradoresOptions] = useState<Colaborador[]>([]);
  const [colaboradores, setColaboradores] = useState<Colaborador[]>([]);
  const [filiaisOptions, setFiliaisOptions] = useState<Filial[]>([]);
  const [entregadores, setEntregadores] = useState<Usuario[]>([]);
  const [dataFiltered, setDataFiltered] = useState<Entrega[]>([]);
  const [params, setParamas] = useState<Params>({} as Params);
  const [idsClientes, setIdsClientes] = useState<string>();
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [filiais, setFiliais] = useState<Filial[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const colaboradorApi = new ColaboradorAPI();
  const clienteApi = new ClienteAPI();
  const entregaApi = new EntregaAPI();
  const entregaReportApi = new EntregaReportApi();
  const usuarioApi = new UsuarioAPI();
  const filialApi = new FilialAPI();
  const classes = useStyles(props);
  
  useComponentDidMount(() => {
    appLayoutActions.setTitleToolbar('Entregas');
    appLayoutActions.setCurrentRoute('/app/lista-entregas');
    appLayoutActions.setLoading(true);

    usuarioApi.findAllCouriers()
    .then(res => {
      setEntregadores(res.data);
    }).catch();

    colaboradorApi.finAllByUsuario()
    .then(res => {
      setColaboradores(res.data);
      setColaboradoresOptions(res.data);
    }).catch();
    
    clienteApi.findClientesByUsuario()
    .then(res => {
      setClientes(res.data);
    }).catch();

    filialApi.findFiliaisByUsuario()
    .then(res => {
      setFiliais(res.data);
      setFiliaisOptions(res.data);
    }).catch();

    setFilters(filtros[ScreensKeysEnum.ENTREGAS]?.filters);
    
  });

  useEffect(() => {
    if(clientes.length > 0 && filiais.length > 0 && colaboradores.length > 0 && entregadores.length > 0){
      loadData()
    }
  },[clientes, filiais, colaboradores, entregadores])

  useEffect(()=> {
    if(idsClientes){
      filialApi.findAllByClientes(idsClientes).then(res => setFiliaisOptions(res.data));
      colaboradorApi.finAllByClientes(idsClientes).then(res => setColaboradoresOptions(res.data));
    }
    if(idsClientes === ''){
      filialApi.findFiliaisByUsuario()
      .then(res => setFiliaisOptions(res.data)).catch();
      colaboradorApi.finAllByUsuario()
      .then(res => setColaboradoresOptions(res.data));
    }
  },[idsClientes]); 

  function loadData(){
    if (filters?.length > 0) {
      changeFilters(filters, clientes, filiais, colaboradores,entregadores);
    } else {
    entregaApi
      .getDeliveryList().then((res) => {
          setDataFiltered(res.data as Entrega[]);
      })
      .catch((err) => {
        Swal({
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Ok',
          title: 'Ocorreu um erro',
          text: err.response?.data ? err.response?.data.messageUsuario : 'Falha ao retornar os dados',
          icon: 'error',
        });
      }).finally(() =>  appLayoutActions.setLoading(false));
    }
  }

  function converterData(data: string | Date) {
    if (typeof data === "string") {
      let date = new Date(data);
      const dia = date.getDate().toString().padStart(2, '0');
      const mes = (date.getMonth() + 1).toString().padStart(2, '0');
      const ano = date.getFullYear().toString().slice(-2);
      const horas = date.getHours().toString().padStart(2, '0');
      const minutos = date.getMinutes().toString().padStart(2, '0');
      return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
    }
    return data.toLocaleString();
  }

  function changeFilters(
    data: any, 
    clientes: Cliente[], 
    filiais: Filial[], 
    colaboradores: Colaborador[],
    entregadores: Usuario[]
  ){

    function getFilterValues(data: any[], field: string) {
      return data
        .filter((d: { field: string; }) => d.field === field)
        .map((item: { value: any; }) => item.value);
    }
  
    function getMatchingIds(values: any[], items: any[], idField: string) {
      const ids = [];
      for (const value of values) {
        const matchingItem = items.find((item) => {
          if(idField === 'idCliente') return  item.nmCliente === value;
          if(idField === 'idFilial') return item.nmFilial === value;
          if(idField === 'idColaborador') return  item.nmColaborador === value;
          if(idField === 'idUsuario') return item.nmUsuario === value;
        });
        if (matchingItem) {
          ids.push(matchingItem[idField]);
        }
      }

      return ids;
    }

    const clienteValues = getFilterValues(data, 'cliente');
    const filialValues = getFilterValues(data, 'filial');
    const colaboradorValues = getFilterValues(data, 'colaborador');
    const entregadorValues = getFilterValues(data, 'entregador');
    const nrCrachaValue = data.find((filter: { field: string; }) => filter.field === 'nrCracha')?.value;
    const nrMatriculaValue = data.find((filter: { field: string; }) => filter.field === 'nrMatricula')?.value;
    const dtInicioValue = data.find((filter: { field: string; }) => filter.field === 'dtInicio')?.value;
    const dtTerminoValue = data.find((filter: { field: string; }) => filter.field === 'dtTermino')?.value;

    const idClientes = getMatchingIds(clienteValues, clientes, 'idCliente').join(';');
    const idFiliais = getMatchingIds(filialValues, filiais, 'idFilial').join(';');
    const idColaboradores = getMatchingIds(colaboradorValues, colaboradores, 'idColaborador').join(';');
    const idEntregadores = getMatchingIds(entregadorValues, entregadores, 'idUsuario').join(';');

    setIdsClientes(idClientes);
    let params = '';
    
    if (idClientes !== '') params += '&idCliente=' + idClientes;
    if (idFiliais !== '') params += '&idFilial=' + idFiliais;
    if (idColaboradores !== '') params += '&idColaborador=' + idColaboradores;
    if (idEntregadores !== '') params += '&idEntregador=' + idEntregadores;
    if (nrCrachaValue) params += '&nrCracha=' + nrCrachaValue;
    if (nrMatriculaValue) params += '&nrMatricula=' + nrMatriculaValue;
      setParamas({
        dtInicio: dtInicioValue && moment(dtInicioValue).format('YYYY-MM-DD'),
        dtFim: dtTerminoValue && moment(dtTerminoValue).format('YYYY-MM-DD'),
        params: params
      })
      entregaApi
        .getDeliveryList(
          dtInicioValue && moment(dtInicioValue).format('YYYY-MM-DD'),
          dtTerminoValue && moment(dtTerminoValue).format('YYYY-MM-DD'),
          params
        )
        .then((res) => {
          setDataFiltered(res.data as Entrega[]);
        })
        .catch((err) => {
          Swal({
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: 'Ok',
            title: 'Ocorreu um erro',
            text: err.response?.data ? err.response?.data.messageUsuario : 'Falha ao retornar os dados',
            icon: 'error',
          });
        })
        .finally(() => appLayoutActions.setLoading(false));
  }

  return (
    <div className={classes.rootListagem}>
      <CardFilters
        screenKey={ScreensKeysEnum.ENTREGAS}
        data={dataFiltered ?? []}
        onLoadData={(data) => {
          appLayoutActions.setLoading(true);
          changeFilters(
            data, 
            clientes, 
            filiais, 
            colaboradores, 
            entregadores
        )}}
        onRefreshFilters={() => {}}
        changeFilters={(data) => {
          appLayoutActions.setLoading(true);
          setFilters(data);
          changeFilters(
            data, 
            clientes, 
            filiais, 
            colaboradores, 
            entregadores
          );
        }}
        filtersDefinitions={[
          {
            field: 'cliente',
            label: 'Cliente',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            multiple: true,
            AutoCompleteProps: {
              getLabel: (item) => item.nmCliente,
              getValue: (item) => item.idCliente,
              suggestions: clientes,
              orderField: 'nmCliente',
              searchField: 'nmCliente',
            },
          },
          {
            field: 'filial',
            label: 'Filial',
            multiple: true,
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmFilial,
              getValue: (item) => item.idFilial,
              suggestions: filiaisOptions,
              orderField: 'nmFilial',
              searchField: 'nmFilial',
            },
          },
          {
            field: 'colaborador',
            label: 'Colaborador',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmColaborador,
              getValue: (item) => item.idColaborador,
              suggestions: colaboradoresOptions,
              orderField: 'nmColaborador',
              searchField: 'nmColaborador',
            },
          },
          {
            field: 'entregador',
            label: 'Entregador',
            type: TipoFiltroEnum.AUTOCOMPLETE,
            AutoCompleteProps: {
              getLabel: (item) => item.nmUsuario,
              getValue: (item) => item.idUsuario,
              suggestions: entregadores,
              orderField: 'nmUsuario',
              searchField: 'nmUsuario',
            },
          },
          {
            field: 'nrCracha',
            label: 'Crachá',
            type: TipoFiltroEnum.STRING,
          },
          {
            field: 'nrMatricula',
            label: 'Matrícula',
            type: TipoFiltroEnum.STRING,
          },
          {
            field: 'dtInicio',
            label: 'Data Início',
            multiple: true,
            simpleOperation: true,
            type: TipoFiltroEnum.DATE,
          },
          {
            field: 'dtTermino',
            label: 'Data Término',
            multiple: true,
            simpleOperation: true,
            type: TipoFiltroEnum.DATE,
          },
        ]}
      />
      <GroupButtonGrid
        showAdd={false}
        showEdit={false}
        showDelete={false}
        customButtons={[
          <ButtonGrid ButtonProps={{ color: 'inherit', className: classes.btnDownloadStyles }}
            key={'iconDownload'}
            icon={<SaveAltIcon style={{ color: '#fff' }} />}
            show={true}
            onClick={() => {
              LoadingSwal({ text: 'Gerando arquivo csv', });
              entregaReportApi.downloadCsvFile(params.dtInicio, params.dtFim, params.params).then(res => {
                Swal({
                  showConfirmButton: true,
                  title: 'Sucesso',
                  icon: 'success'
                });
                window.location.assign(`${res.data}`);
              })
              .catch(err => {
                Swal({
                  showConfirmButton: false,
                  showCancelButton: true,
                  cancelButtonText: 'Ok',
                  title: 'Ocorreu um erro',
                  text: err.response?.data.messageUsuario ? err.response?.data.messageUsuario : 'Falha ao gerar arquivo csv',
                  icon: 'error'
                });
              })
            }}
          />
        ]}
      />
      <div className={`${classes.containerDataTable} tabela`}>
        <DataTable<Entrega>
          data={dataFiltered}
          columns={[
            {
              headerName: 'Data Entrega',
              field: 'dtEntrega',
              col: 1,
              valueGetter: (node) => converterData(node.data.dtEntrega),
              comparator: (dateA, dateB) => moment(dateA, 'DD/MM/YY HH:mm:ss').valueOf() - moment(dateB, 'DD/MM/YY HH:mm:ss').valueOf()
            },
            {
              field: 'nmCliente',
              headerName: 'Cliente',
              col: 2,
            },
            {
              field: 'nmFilial',
              headerName: 'Filial',
              col: 1,
            },
            {
              field: 'nrCracha',
              headerName: 'Crachá',
              col: 1,
              comparator: (a, b) => parseInt(a, 10) - parseInt(b, 10),
            },
            {
              field: 'nrMatricula',
              headerName: 'Matrícula',
              col: 1,
              comparator: (a, b) => parseInt(a, 10) - parseInt(b, 10),
            },
            {
              field: 'nmColaborador',
              headerName: 'Colaborador',
              col: 2,
            },
            {
              field: 'nmCesta',
              headerName: 'Cesta',
              col: 1,
            },
            {
              field: 'dsSetor',
              headerName: 'Setor',
              col: 1,
            },
            {
              field: 'nmEntregador',
              headerName: 'Entregador',
              col: 2,
            },
          ]}
          rowSelectionType={'multiple'}
          rowsPerPageEnabled={false}
          showPagination
        />
      </div>
    </div>
  );
}

export default ListaEntrega;